<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    attachModule="tomografiaDeCorneaGalilei"
    :hasExam="hasAttendanceValues('exam')"
    @getData="importAttendanceData(null, $event)"
  >
  <b-row>
    <b-col>
      <b-row>
        <b-col cols="6">
          <div class="custom-input-group" >
            <div class="custom-input-title h-38">
              <div class="eye-area h-38 no-br" >
                <EyeFill /> D
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                K médio
              </div>
              <div class="custom-input no-bb">
                <input
                  autocomplete="off"
                  :value="form.fields.KmedioOlhoDireito"
                  :readonly="!canEdit"
                  @input="el => updateForm('KmedioOlhoDireito', el.target.value)"
                  @blur="updateMedicalRecord(form)"
                  type="text"
                  class="form-control"
                  placeholder="Resultado"
                />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                Mais Plano (K1)
              </div>
              <div class="custom-input no-bb">
                <input
                    autocomplete="off"
                    :value="form.fields.planoOlhoDireito"
                    :readonly="!canEdit"
                    @input="el => updateForm('planoOlhoDireito', el.target.value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                Mais Curvo (K2)
              </div>
              <div class="custom-input no-bb">
                <input
                  autocomplete="off"
                  :value="form.fields.curvoOlhoDireito"
                  :readonly="!canEdit"
                  @input="el => updateForm('curvoOlhoDireito', el.target.value)"
                  @blur="updateMedicalRecord(form)"
                  type="text"
                  class="form-control"
                  placeholder="Resultado"
                />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                Astigmatismo
              </div>
              <div class="custom-input no-bb">
                <input
                  autocomplete="off"
                  :value="form.fields.astigmatismoOlhoDireito"
                  :readonly="!canEdit"
                  @input="el => updateForm('astigmatismoOlhoDireito', el.target.value)"
                  @blur="updateMedicalRecord(form)"
                  type="text"
                  class="form-control"
                  placeholder="Resultado"
                />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                Kmax (ápice)
              </div>
              <div class="custom-input no-bb">
                <input
                  autocomplete="off"
                  :value="form.fields.kmaxOlhoDireito"
                  :readonly="!canEdit"
                  @input="el => updateForm('kmaxOlhoDireito', el.target.value)"
                  @blur="updateMedicalRecord(form)"
                  type="text"
                  class="form-control"
                  placeholder="Resultado"
                />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                Ponto mais fino
              </div>
              <div class="custom-input no-bb">
                <input
                  autocomplete="off"
                  :value="form.fields.finoOlhoDireito"
                  :readonly="!canEdit"
                  @input="el => updateForm('finoOlhoDireito', el.target.value)"
                  @blur="updateMedicalRecord(form)"
                  type="text"
                  class="form-control"
                  placeholder="Resultado"
                />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                Dist. Kappa
              </div>
              <div class="custom-input no-bb">
                <input
                  autocomplete="off"
                  :value="form.fields.kappaOlhoDireito"
                  :readonly="!canEdit"
                  @input="el => updateForm('kappaOlhoDireito', el.target.value)"
                  @blur="updateMedicalRecord(form)"
                  type="text"
                  class="form-control"
                  placeholder="Resultado"
                />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                KPI
              </div>
              <div class="custom-input no-bb">
                <input
                  autocomplete="off"
                  :value="form.fields.kpiOlhoDireito"
                  :readonly="!canEdit"
                  @input="el => updateForm('kpiOlhoDireito', el.target.value)"
                  @blur="updateMedicalRecord(form)"
                  type="text"
                  class="form-control"
                  placeholder="Resultado"
                />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area">
                Laudo
              </div>
              <div class="custom-input no-bb bbr">
                <TextArea
                    id="laudoOlhoDireito"
                    rows="1"
                    :value="form.fields.laudoOlhoDireito"
                    :readonly="!canEdit"
                    @input="value => updateForm('laudoOlhoDireito', value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control border-text"
                    placeholder="Resultado"
                  />
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="custom-input-group" >
            <div class="custom-input-title h-38">
              <div class="eye-area h-38 no-br" >
                <EyeFill /> E
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                K médio
              </div>
              <div class="custom-input no-bb">
                <input
                  autocomplete="off"
                  :value="form.fields.KmedioOlhoEsquerdo"
                  :readonly="!canEdit"
                  @input="el => updateForm('KmedioOlhoEsquerdo', el.target.value)"
                  @blur="updateMedicalRecord(form)"
                  type="text"
                  class="form-control"
                  placeholder="Resultado"
                />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                Mais Plano (K1)
              </div>
              <div class="custom-input no-bb">
                <input
                  autocomplete="off"
                  :value="form.fields.planoOlhoEsquerdo"
                  :readonly="!canEdit"
                  @input="el => updateForm('planoOlhoEsquerdo', el.target.value)"
                  @blur="updateMedicalRecord(form)"
                  type="text"
                  class="form-control"
                  placeholder="Resultado"
                />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area no-br">
                  Mais Curvo (K2)
                </div>
                <div class="custom-input no-bb">
                  <input
                    autocomplete="off"
                    :value="form.fields.curvoOlhoEsquerdo"
                    :readonly="!canEdit"
                    @input="el => updateForm('curvoOlhoEsquerdo', el.target.value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
              </div>
            </div>
            <div class="custom-input no-bb">
                <div class="eye-area no-br">
                    Astigmatismo
                </div>
                <div class="custom-input no-bb">
                  <input
                    autocomplete="off"
                    :value="form.fields.astigmatismoOlhoEsquerdo"
                    :readonly="!canEdit"
                    @input="el => updateForm('astigmatismoOlhoEsquerdo', el.target.value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
              </div>
            </div>
            <div class="custom-input no-bb">
                <div class="eye-area no-br">
                    Kmax (ápice)
                </div>
                <div class="custom-input no-bb">
                  <input
                    autocomplete="off"
                    :value="form.fields.kmaxOlhoEsquerdo"
                    :readonly="!canEdit"
                    @input="el => updateForm('kmaxOlhoEsquerdo', el.target.value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
              </div>
            </div>
            <div class="custom-input no-bb">
                <div class="eye-area no-br">
                    Ponto mais fino
                </div>
                <div class="custom-input no-bb">
                  <input
                    autocomplete="off"
                    :value="form.fields.finoOlhoEsquerdo"
                    :readonly="!canEdit"
                    @input="el => updateForm('finoOlhoEsquerdo', el.target.value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
              </div>
            </div>
            <div class="custom-input no-bb">
                <div class="eye-area no-br">
                    Dist. Kappa
                </div>
                <div class="custom-input no-bb">
                  <input
                    autocomplete="off"
                    :value="form.fields.kappaOlhoEsquerdo"
                    :readonly="!canEdit"
                    @input="el => updateForm('kappaOlhoEsquerdo', el.target.value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
              </div>
            </div>
            <div class="custom-input no-bb">
                <div class="eye-area no-br">
                    KPI
                </div>
                <div class="custom-input no-bb">
                  <input
                    autocomplete="off"
                    :value="form.fields.kpiOlhoEsquerdo"
                    :readonly="!canEdit"
                    @input="el => updateForm('kpiOlhoEsquerdo', el.target.value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
              </div>
            </div>
            <div class="custom-input no-bb">
              <div class="eye-area">
                Laudo
              </div>
              <div class="custom-input no-bb">
                <TextArea
                  id="laudoOlhoEsquerdo"
                  rows="1"
                  :value="form.fields.laudoOlhoEsquerdo"
                  :readonly="!canEdit"
                  @input="value => updateForm('laudoOlhoEsquerdo', value)"
                  @blur="updateMedicalRecord(form)"
                  type="text"
                  class="form-control border-text"
                  placeholder="Resultado"
                />
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
  </FormWrapper>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import attendanceDataMixin from '@/mixins/Attendance/attendanceDataMixin'

export default {
  components: {
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
    EyeFill: () => import('@/assets/icons/eye-fill.svg'),
    TextArea: () => import('@/components/General/TextArea'),
    // Copy: () => import('@/assets/icons/copy.svg')
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.tomografiaDeCorneaGalilei,
      medicalRecordsStore: state => state.attendance.medicalRecordsStore
    }),
    ...mapGetters('attendance', ['exam'])
  },
  mixins: [attendanceDataMixin('medicalRecordsStore')],
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/tomografiaDeCorneaGalilei', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      Object.keys(this.form.fields).forEach(key => {
        this.form.fields[key] = null
      });
    },
    async importAttendanceData(medicalRecords = null, type) {
      if (!this.hasAttendanceValues(type)) return
      await this.simpleImportData(medicalRecords, type)
    }
  }
}
</script>

<style lang="scss" scoped>
  .custom-input-group {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--neutral-300);
    border-radius: 8px;
    margin: 24px 0 16px 0;

    .custom-input-title {
      flex: 1;
      border: 8px;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid var(--neutral-300);
      // justify-content: center;

      &.h-38 {
      height: 38px;
      }

      .eye-area {
        width: 100%;
        background-color: var(--neutral-100);
        border-right: 1px solid var(--neutral-300);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px 8px 0 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        &.no-br {
          border-right: 0 !important;
        }

        svg {
          width: 16px;
          height: 16px;
          fill: var(--type-active);
          margin-right: 4px;
        }
      }
    }
    .custom-input {
      flex: 1;
      border: 8px;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid var(--neutral-300);
      // justify-content: center;

      &.h-38 {
      height: 38px;
      }
      .form-control {
        border-radius: 0 !important;
        border-right: 0 !important;
        border-left: 0 !important;
        border-top: 1 solid var(--neutral-300) !important;
      }

      .padding-row{
        padding-right: 8px !important;
      }
      .border-text {
        border-bottom: 0px !important;
        border-radius: 0px 0px 10px 0px !important;
      }
      .eye-area {
        width: 140px;
        background-color: var(--neutral-100);
        border-right: 1px solid var(--neutral-300);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px 0 0 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        &.no-br {
          border-radius: 0 !important;
          border-bottom: 1px solid var(--neutral-300);
        }

        svg {
          width: 16px;
          height: 16px;
          fill: var(--type-active);
          margin-right: 4px;
        }
      }

      &.no-bb {
        border-bottom: 0;

        .eye-area {
          border-radius: 0 0 0 8px;
        }
      }
      &.bbr {
          border-radius: 0 0 8px 0 !important;
        }
    }
  }

  .border {
    border-right: 1px solid var(--neutral-300);
  }

  .eye-box-container {
    background-color: var(--neutral-100);
    align-items: center;
    .eyeValueChange {
      visibility: hidden;
    }

    &:hover {
      .eyeValueChange {
        visibility: visible;
      }
    }
  }
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    fill: var(--blue-500)
  }

  .new-topografia {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    top: 0;
    right: 10px;
    z-index: 10;
    padding-right: 30px;

    .icon {
      width: 20px;
      height: 20px;
      fill: var(--blue-500);
      cursor: pointer;

      &.trash {
        fill: none;
        stroke: var(--states-red-soft);
      }

      &.edit {
        fill: none;
        stroke: var(--blue-500);
      }

      &.close {
        fill: var(--type-active);
      }
    }
  }

  .blue {
    color: var(--blue-500);
    display: inline-block;
  }
</style>